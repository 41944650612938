import { request, gql } from 'graphql-request';
import { downloadAppQrImgUrl } from './qrcode';

const params = new URLSearchParams(window.location.search);
const COMPANY = location.pathname.substring(1);
const EXPERT = params.get('expert');

const AVATAR_FALLBACK_URL = '/img/person-placeholder.jpg';
const NOT_FOUND_PAGE_URL = 'https://www.amazd.co/';
const WIDGET_SCRIPT_ID = 'widget-script';
const SETTINGS_BASE = {
  company: COMPANY || 'amazd',
  maximize: true,
};

const initialLoadSettings = { ...SETTINGS_BASE };

if (EXPERT) {
  initialLoadSettings.expert = EXPERT;
} else {
  initialLoadSettings.maximize = false;
  initialLoadSettings.showDeflectionScreen = true;
}

window.amazdSettings = initialLoadSettings;

loadWidgetScript();

const query = gql`
  query ($args: CompanyExpertsV2Dto!, $amazdArgs: CompanyExpertsV2Dto!) {
    companyExpertsV2(args: $args) {
      id
      role
      handle
      firstName
      lastName
      avatar
      description
      companyDescription
      companyName
      companyHandle
      tags {
        id
        tagId
        label
        __typename
      }
      __typename
    }
      amazdExperts:  companyExpertsV2(args: $amazdArgs) {
          handle
          id
          firstName
          lastName
          avatar
          description
          companyDescription
          companyName
          companyHandle
          tags {
              id
              tagId
              label
              __typename
          }
          __typename
      }
    
      companyPublic(handle: "${COMPANY || 'amazd'}") {
          key_account_manager_id
          is_landing_page_enabled
      }
  }
`;

request(BACKEND_URL, query, {
  args: {
    companyHandle: COMPANY || 'amazd',
    spokenLanguages: ['en'],
  },
  amazdArgs: {
    companyHandle: 'amazd',
    spokenLanguages: ['en'],
  },
})
  .then((data) => {
    console.log(data);

    if (!data.companyPublic.is_landing_page_enabled) {
      console.warn('redirecting to 404 page');
      return (location.href = NOT_FOUND_PAGE_URL);
    }

    if (!data.amazdExperts.length) {
      //  no amazd experts
      console.log('no amazd experts');

      document.getElementById('expert-amazd-container').remove();
    }

    if (!data.companyExpertsV2.length) {
      //  no experts for the company
      console.log('no experts');

      document.getElementById('experts-container').remove();
      document.getElementById('expert-qr-container').remove();

      return;
    }

    const expert1 = data.companyExpertsV2[0];
    const expert2 = data.companyExpertsV2[1] || expert1;

    window.EXPERT1 = expert1;
    window.EXPERT2 = expert2;

    const accountManagerId = data.companyPublic.key_account_manager_id;
    if (!accountManagerId) {
      console.log('no key account manager set for the company');
      document.getElementById('expert-amazd-container').remove();
    } else {
      window.KEY_EXPERT = data.amazdExperts.find((expert) => expert.id === accountManagerId);
      console.log('KEY_ACCOUNT_MANAGER %o', KEY_EXPERT);

      document.getElementById('key-expert-name').innerText = `${window.KEY_EXPERT.firstName} ${
        window.KEY_EXPERT.lastName || ''
      }`;
      document.getElementById('key-expert-name2').innerText = window.KEY_EXPERT.firstName;
      document.getElementById('key-expert-avatar').src = window.KEY_EXPERT.avatar || AVATAR_FALLBACK_URL;
    }

    const owner = findOwner(data.companyExpertsV2) || window.EXPERT1;
    window.OWNER = owner;
    if (!findOwner(data.companyExpertsV2)) {
      console.log('no OWNER, user expert1 instead');
    } else {
      console.log('OWNER %o', owner);
    }
    document.getElementById('qr-expert-name').innerText = `${owner.firstName} ${owner.lastName || ''} `;
    document.getElementById('qr-expert-company').innerText = owner.companyName;
    downloadAppQrImgUrl(owner.handle).then((resp) => {
      console.log(resp);
      document.getElementById('qr-code').src = resp;
    });

    document.getElementById('company-name').innerText = expert1.companyName;

    document.getElementById('expert1-fullname').innerText = `${expert1.firstName} ${expert1.lastName}`;
    document.getElementById('expert2-fullname').innerText = `${expert2.firstName} ${expert2.lastName}`;

    document.getElementById('expert1-name').innerText = expert1.firstName;
    document.getElementById('expert2-name').innerText = expert2.firstName;

    document.getElementById('expert1-avatar').src = expert1.avatar || AVATAR_FALLBACK_URL;
    document.getElementById('expert2-avatar').src = expert2.avatar || AVATAR_FALLBACK_URL;

    //  hide loading indicator and show the page
    document.getElementById('loading-indicator').remove();
    document.getElementById('content').style.display = 'block';
  })
  .catch((e) => {
    const error = e.response.errors[0];
    document.getElementById('errors').innerText = error.message;
    if (error.extensions.status === 404) {
      console.log('redirect to 404');
      return (location.href = NOT_FOUND_PAGE_URL);
    }
  });

let lastUsedCommand = 'open-default';

document.getElementById('open').addEventListener('click', () => {
  window.amazdSettings = {
    ...SETTINGS_BASE,
    showDeflectionScreen: true,
  };

  if (lastUsedCommand !== 'open-default') {
    reloadWidgetScript();
  } else {
    amazd.toggle();
  }

  lastUsedCommand = 'open-default';
});

document.getElementById('open-experts').addEventListener('click', () => {
  window.amazdSettings = {
    ...SETTINGS_BASE,
    showExpertSelectScreen: true,
  };

  if (lastUsedCommand !== 'open-experts') {
    reloadWidgetScript();
  } else {
    amazd.toggle();
  }

  lastUsedCommand = 'open-experts';
});

document.getElementById('open-experts').addEventListener('mousedown', () => {
  const x = document.getElementById('hand-icon');
  x.style.display = 'none';
});

document.getElementById('open-experts').addEventListener('mouseup', () => {
  const x = document.getElementById('hand-icon');
  x.style.display = 'block';
});

document.getElementById('expert1-connect').addEventListener('click', () => {
  if (!window.EXPERT1) {
    return;
  }

  window.amazdSettings = {
    ...SETTINGS_BASE,
    expert: window.EXPERT1.handle,
  };

  if (lastUsedCommand !== 'expert1-connect') {
    reloadWidgetScript();
  } else {
    amazd.toggle();
  }

  lastUsedCommand = 'expert1-connect';
});

document.getElementById('expert2-connect').addEventListener('click', () => {
  if (!window.EXPERT2) {
    return;
  }

  window.amazdSettings = {
    ...SETTINGS_BASE,
    expert: window.EXPERT2.handle,
  };

  if (lastUsedCommand !== 'expert2-connect') {
    reloadWidgetScript();
  } else {
    amazd.toggle();
  }

  lastUsedCommand = 'expert2-connect';
});

document.getElementById('key-expert-connect').addEventListener('click', () => {
  if (!window.KEY_EXPERT) {
    return;
  }

  window.amazdSettings = {
    ...SETTINGS_BASE,
    expert: window.KEY_EXPERT.handle,
  };

  if (lastUsedCommand !== 'key-expert-connect') {
    reloadWidgetScript();
  } else {
    amazd.toggle();
  }

  lastUsedCommand = 'key-expert-connect';
});

//

document.getElementById('copy-app-url').addEventListener('click', () => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    const container = document.getElementById('copy-app-url');
    //show tooltip
    container.setAttribute('data-tootik', 'Copied!');
    container.setAttribute('data-tootik-conf', 'invert delay shadow');

    setTimeout(() => {
      //hide tooltip
      container.removeAttribute('data-tootik');
      container.removeAttribute('data-tootik-conf');
    }, 1500);

    return navigator.clipboard.writeText('https://app.amazd.co/');
  }

  return Promise.reject('The Clipboard API is not available.');
});

const OWNER_ROLE = 'OWNER';

function findOwner(experts) {
  return experts.find((expert) => expert.role === OWNER_ROLE);
}

function reloadWidgetScript() {
  cleanUpWidgetScript();
  loadWidgetScript();
}

function cleanUpWidgetScript() {
  const widgetScript = document.getElementById(WIDGET_SCRIPT_ID);
  if (widgetScript) {
    widgetScript.remove();
    delete window.amazd;
  }

  const wrapper = document.getElementById('amazd-widget-wrapper');
  if (wrapper) {
    wrapper.remove();
  }
}

function loadWidgetScript() {
  ((d, s) => {
    var e = d.createElement(s),
      t = d.getElementsByTagName(s)[0];
    e.async = true;
    e.id = WIDGET_SCRIPT_ID;
    e.src = `${SCRIPT_HOST}/widget.js`;
    t.parentNode.insertBefore(e, t);
  })(document, 'script');
}
