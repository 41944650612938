import { FirebaseDynamicLinks } from 'firebase-dynamic-links';

import QRCode from 'qrcode';
import queryString from 'query-string';

const COMPANY = location.pathname.substring(1);

const deepLinkPrefixUrl = NEXT_PUBLIC_DEEP_LINK_URL || '';
const firebaseWebApiKey = NEXT_PUBLIC_FIREBASE_API_KEY || '';

const firebaseDynamicLinks = new FirebaseDynamicLinks(firebaseWebApiKey);

const createDynamicLink = async (deepLink) => {
  const { shortLink } = await firebaseDynamicLinks.createLink({
    dynamicLinkInfo: {
      domainUriPrefix: deepLinkPrefixUrl,
      link: deepLink,
    },
  });

  return shortLink;
};

export const downloadAppQrImgUrl = async (expertHandle, margin) => {
  const hostName = window.location.hostname;
  const port = window.location.port;

  const webLink = port
    ? `http://${hostName}:${port}/${COMPANY || 'amazd'}?expert=${expertHandle}`
    : `https://${hostName}/${COMPANY || 'amazd'}?expert=${expertHandle}`;
  const deepLink = queryString.stringifyUrl({ url: webLink, query: { expert: expertHandle } });
  console.log('deepLink ' + deepLink);
  const dynamicLink = await createDynamicLink(deepLink);
  const qrImageUrl = await QRCode.toDataURL(dynamicLink, {
    margin: margin === undefined ? 4 : margin,
  });

  return qrImageUrl;
};
